import React, {useContext} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "./seo"
import styled, {css} from "styled-components"
import Header from "./header"
import Footer from "./footer"
import Cart from '../Shop/cart';
import {CartContext} from '../../store/cartState';
import "./layout.css"

const Container = styled.div`
  margin: 30px 5px 5px 5px;      
  padding-top: 1.8rem;  
  padding-bottom: 1rem;    
  display: flex;
  justify-content: center;    
  width: 100%;
  transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;       
  ${props => props.cartIsOpen && css`    
    width: calc(100% - 300px);  
  `}  
`
const Root = styled.div`
  position: relative;
  min-height: 85vh;
`
const CartContainer = styled.div`
  display: flex; 
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const {cartIsOpen} = useContext(CartContext); 

  return (
    <Root>
      <SEO title="reap" />      
      <Header siteTitle={data.site.siteMetadata.title} />      
        <CartContainer>
          <Container cartIsOpen={cartIsOpen}>
            {children}        
          </Container>
          <Cart />
        </CartContainer>           
      <Footer />
    </Root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
