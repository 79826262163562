import React, {useContext, useState} from "react"
import Img from "gatsby-image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styled, {css}  from "styled-components"
import {  ButtonBase } from '../Layout/pageParts';
import {Colours} from '../Layout/colours';
import {CartContext} from '../../store/cartState';

const QuantityContainer = styled.div`
    display: flex;
    border: 1px solid ${Colours.Granite};
    padding:0px;    
    min-width: 50px;
    height: 34px;
    align-items: center;
    justify-content: center;
    margin-left:10px;
`
const Quantity = styled.div`
    margin-left:10px;
    margin-right:10px;
`;

const QuantityButton = styled(ButtonBase)`    
    ${props => props.disabled && css`    
    opacity: 0.65; 
    cursor: not-allowed;
  `}  
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;    
    width:250px;
    padding-left:10px;
    padding-right:10px;  
`;

const TitleRowContainer = styled.div`    
    align-items: center;    
    display: flex;    
    flex-direction:row;           
    justify-content: space-between;    
    margin-right: 50px;
`;

export const CartItemTitle = styled.h2`
  font-size: 16px;    
  font-weight: 400;  
  margin-bottom: 0px;
`;

const CartItemRow = styled.div`
    margin-top:15px;    
`;

const CartItem = ({name, image, price, quantity}) => {    
    const minQuantity = 1;
    const maxQuantity = 20;
    const { removeAllProductFromCart, decreaseQuantityOfProductFromCart, increaseQuantityOfProductFromCart } = useContext(CartContext);
    const [showRemove, setShowRemove] = useState(false);

    return (    
        <CartItemRow onMouseEnter={() => setShowRemove(true)} onMouseLeave={()=> setShowRemove(false)}>
        <TitleRowContainer>             
        <CartItemTitle>{name}&nbsp;&nbsp;</CartItemTitle>
        <CartItemTitle>£{price}</CartItemTitle>                     
        <ButtonBase hide={!showRemove} onClick={()=> removeAllProductFromCart(name)}><FontAwesomeIcon size="xs" color='#515151' icon={faTimes} /></ButtonBase>
        </TitleRowContainer>    
        <Container>       
                <Img alt={`${name} oil`} fluid={image.childImageSharp.fluid} style={{ width: "25%", height: "0%" }} />  
                <QuantityContainer>
                <QuantityButton disabled={quantity <= minQuantity} onClick={()=> decreaseQuantityOfProductFromCart(name)}>-</QuantityButton>
                <Quantity>{quantity}</Quantity>
                <QuantityButton disabled={quantity >= maxQuantity} onClick={()=> increaseQuantityOfProductFromCart(name)}>+</QuantityButton>
            </QuantityContainer>                 
        </Container>          
        </CartItemRow>
    )
}

export default CartItem
