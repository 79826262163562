import { Link } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import logo from "../../images/ReapLogo.png"
import { Sizes } from './sizes';
import {CartContext} from '../../store/cartState';
import {ButtonBase} from './pageParts';

const NavBar = styled.div`     
    z-index:99;                 
    position: fixed;    
    top:0;
    left: 0;
    right: 0;                   
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;       
    ${props => props.light && css`    
    background: white;       
  `}  

    @media screen and ${Sizes.mobileS} {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;            
        align-items: center;  
        background: transparent;        
        ${props => props.light && css`    
        background: white;       
    `}
    }   
`;

const MainNav = styled.div`    
    display: none;
    flex-direction:column; 

    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;       

    ${props => props.cartIsOpen && css`    
         padding-right: 250px;  
    `}  

    ${props => props.showMenu && css`
        display: flex;    
    `}

    @media screen and ${Sizes.mobileS} {
        display: flex;
        margin-right: 50px;
        flex-direction: row;
        justify-content: flex-end;
        }
`;

const NavLink = styled(props => <Link {...props} />)` 
    padding:0px 10px;    
    font-weight: 400;
    font-size:14px;
    text-align: center;  
    background:transparent;
    color: #515151;
    transition: color .2s ease-in;        
`;

const LogoLink = styled(props => <Link {...props} />)`     
    margin-left: 20px;    
    
`;

const HamburgerMenu = styled.label`
    position: absolute;
    top: 15px;
    right: 70px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;

    ${props => props.cartIsOpen && css`    
         right: 300px;  
    `}  

    @media screen and ${Sizes.mobileS} {
    display: none;
    
    }
`;

const CartButton = styled(ButtonBase)`
    position: absolute;
    top: 10px;
    right: 10px;    

    ${props => props.flashCart && css`
        opacity: 0.5;    
        transition: all 300ms ease-in;
  `}
`;

const Header = ({ siteTitle }) => {    
    const { toggleCart, cartIsOpen, cartCount} = useContext(CartContext);  
    const [hasScrolledDown, setHasScrolledDown] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [flashCart, setFlashCart] = useState(false);

    useEffect(() => {
        if(!cartIsOpen){
            setFlashCart(true);
            const timer = setTimeout(()=>{
                setFlashCart(false);
            },500)

            return () => clearTimeout(timer);
        }
    }, [cartCount, cartIsOpen])

    useEffect(() => {

        const handleScroll = () => {
            const bodyScrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            const scrolledDownEnough = bodyScrollTop > 5 ? true : false;
            setHasScrolledDown(scrolledDownEnough);
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    return (
        <NavBar light={hasScrolledDown || showMenu}>                        
            <HamburgerMenu htmlFor="chkToggle" cartIsOpen={cartIsOpen}>
                <FontAwesomeIcon size="lg" color='#515151' icon={faBars} />
            </HamburgerMenu>
            <LogoLink to="/">
                <img src={logo} height='60' width='150' alt='reap logo'></img>
            </LogoLink>
            <input type="checkbox" id="chkToggle" style={{ display: 'none' }} checked={showMenu} onChange={(e) => setShowMenu(e.target.checked)}></input>
            
            <MainNav showMenu={showMenu} cartIsOpen={cartIsOpen}>                
                <NavLink to="/joinUs">join us</NavLink>
                <NavLink to="/shop">shop</NavLink>
                <NavLink to="/about">about me</NavLink>
                <NavLink to="/contact">contact me</NavLink>
                <NavLink to="/blog">blog</NavLink>
            </MainNav>           
            <CartButton flashCart={flashCart} cartIsOpen={cartIsOpen} onClick={() => toggleCart()}> <FontAwesomeIcon size="lg" color='#515151' icon={faShoppingCart} /></CartButton> 
        </NavBar >

    )
}

export default Header