import React from 'react';
import styled from "styled-components"
import {Heading, NavLink} from '../Layout/pageParts';

const Container = styled.div`
    display:flex; 
    flex-direction:column;   
`
const EmptyCart = () => {
    return (
    <Container>
        <Heading>Empty cart</Heading>        
        <NavLink state={{closeCart: true}} to="/shop">lets fix that</NavLink>            
    </Container>)
}

export default EmptyCart;