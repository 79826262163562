import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import styled, { css } from "styled-components"
import {CartContext} from '../../store/cartState';
import { ButtonBase} from '../Layout/pageParts';
import EmptyCart from './emptyCart';
import CartDetails from './cartDetails';
import CartSummary from './cartSummary';
import { Colours } from '../Layout/colours';

const Container = styled.div`
  background-color:white;
  bottom: 0;
  position: fixed;
  border-left:1px solid ${Colours.Granite};  
  padding: 0px 5px 5px 10px;  
  right: 0;
  top: -1px;
  transform: translateX(100%);
  transition: transform 1s;
  width: 290px;  
  will-change: transform;
  z-index: 1000;  
  overflow-y: auto;
  ${props => props.open && css`    
    transform: translateX(0%);   
  `}  
`;

const TitleRow = styled.div`
    display:flex;    
`
const FooterRow = styled.div`       
    display: flex;    
    flex-direction:row;           
    justify-content: space-between;    
    margin-top:40px;
    margin-right: 25px;
`;

const Cart = () => {

const {cartCount, cartIsOpen, toggleCart} = useContext(CartContext);  

console.log('rendering cart')

const cartDetails = cartCount === 0 ? <EmptyCart /> : <CartDetails /> ;
const cartSummary = cartCount === 0 ? null : <CartSummary /> ;

return <Container open={cartIsOpen}>
          <TitleRow>              
              <ButtonBase onClick={() => toggleCart()}> <FontAwesomeIcon size="lg" color='#515151' icon={faLongArrowAltRight} /></ButtonBase>
          </TitleRow>            
            {cartDetails}            
            {cartSummary}
            <FooterRow>   
              <ButtonBase onClick={() => toggleCart()}><FontAwesomeIcon size="lg" color='#515151' icon={faLongArrowAltRight} /></ButtonBase>
              <ButtonBase onClick={() => toggleCart()}>Hide Cart</ButtonBase>
            </FooterRow>    
        </Container>
}

export default Cart;