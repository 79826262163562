import React, {useContext} from 'react';
import styled from "styled-components"
import {CartContext} from '../../store/cartState';
import CartItem from './cartItem';
import {Colours} from '../Layout/colours';

const Container = styled.div`
    display:flex; 
    flex-direction:column;   
    margin-top: 10px;    
`

export const Line = styled.hr` 
    background: ${Colours.Granite};
    margin-top: 10px;
`;


const CartDetails = () => {
    const { cartItems } = useContext(CartContext);
     
    const cartItemRows = Array.from(cartItems.values()).map(v => {
        console.log('Logging Cart Item');        
        console.log(v);
        return <CartItem key={v.product.name} name={v.product.name} price={v.product.price} quantity={v.quantity} image={v.product.image} />   
    });   

    return (
    <Container>
        YourCart
        <Line />
        {cartItemRows}
    </Container>)
}

export default CartDetails;