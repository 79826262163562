import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import logo from "../../images/ReapIcon.png"
import { Colours } from './colours';

const FooterRoot = styled.div`    
  width:100%;            
  position: absolute;
  bottom: 0;  
  height: 1rem; 
`;

const Container = styled.div`
    display:flex;
    align-items:center;    
    justify-content:center;    
    margin-left: 2%;
    margin-right: 2%;            
    margin-bottom: 0px;
    font-size:14px;         
     
`;

const ContainerLinks = styled(Container)`             
     padding-bottom: 50px;
`;

const Line = styled.hr` 
    flex:1;          
    vertical-align:bottom;
    border: none;
    height: 2px;    
    margin-bottom: 1px;
    background: ${Colours.Granite};
`;

const Logo = styled.div`    
    width: 40px;
    vertical-align:text-top;    
    padding:0;
    margin:0;
`;

const NavLink = styled(props => <Link {...props} />)`     
    font-weight: 400;
    text-align: center;  
    background:transparent;
    color: #515151;
    transition: color .2s ease-in;         
`;

const Col = styled.div`    
    display:flex;        
    flex-direction:column;    
    padding-left:10px;
    padding-right: 10px;
    margin:3px;
`;

const Footer = () => {

    const doterra = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "doterra-wellness-advocate-black.jpg" }) {
        childImageSharp {          
            fixed(width: 62, height: 21) {
                ...GatsbyImageSharpFixed              
          }
        }
      }
    }
    `)      
    


    return (
        <FooterRoot>
            <Container>
                <Line />
                <Logo><img src={logo} height='40' width='40' alt='reap icon'></img></Logo>
                <Line />            
            </Container>
            <ContainerLinks>
                <Col>HAVE QUESTIONS?</Col>
                <Col>                                        
                    <NavLink to="/contact">send a message</NavLink>
                </Col>
                <Col>                
                    <a href="https://www.facebook.com/groups/614900359336739/">
                        <FontAwesomeIcon icon={faFacebookF} />     
                    </a>                                                
                </Col>
                <Col>                
                    <a href="https://www.instagram.com/gemmacglover/">
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                </Col>
                <Col>
                    <a href="https://www.mydoterra.com/gemmaglover">
                        <Img alt="doterra logo" fixed={doterra.fileName.childImageSharp.fixed} style={{
                marginTop:'5px'
            }}/>                    
                    </a>
                </Col>                
            </ContainerLinks>            
        </FooterRoot>
    )
}

export default Footer;