import React, { useContext, useState } from "react";
import { navigate  } from "gatsby"
import { useStripe } from "@stripe/react-stripe-js";
import styled, { keyframes} from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import {CartContext} from '../../store/cartState';
import { Title} from '../Layout/pageParts';
import { Colours } from '../Layout/colours';

const Container = styled.div`       
    display: flex;    
    flex-direction:column;       
    margin-top: 50px;    
`;

const ContainerRow = styled.div`       
    display: flex;    
    flex-direction:row;           
    justify-content: space-between;    
    margin-right: 50px;
`;

export const Line = styled.hr` 
    background: ${Colours.Granite};
    margin-top: 10px;
`;

const PayButton = styled.button`
display: inline-block;
  border-radius: 3px;
  padding: 0.5rem 0;
  margin: 0.5rem 0rem;
  width: 13rem;
  background: white;
  color:black;
  font-size: 18px;
  border: 1px solid white;    
  background: ${Colours.Dark};
  color: white;   
  opacity:0.9;    
  text-align: center;  
  :hover {    
    background: ${Colours.Light};
    color: white;   
    opacity:0.8;    
  }
`

const BounceAnimation = keyframes`
  0% {  opacity:0.2; }
  50% { opacity:0.4; }
  100% { opacity:0.6;}
`;
   
const ProcessingButton = styled(PayButton)`
  animation: ${BounceAnimation} 0.9s linear infinite;
`;

const CartSummary = () => {
  const stripe = useStripe();
  const {cartTotal, cartItems} = useContext(CartContext);  
  const [processing, setProcessing] = useState(false);

  const convertToPostRequestBody = () => {
    const items = Array.from(cartItems.values())
    const body = items.map(i => {
      return {id: i.product.id, name: i.product.name, price: i.product.price, quantity: i.quantity};
    });
    return body;
  }

  const handleClick = async (event) => {

    setProcessing(true);
    try {        
    console.log('sending request for session id')                ;
    const response = await fetch('https://helloreapshop.azurewebsites.net/api/CreateSession?code=H7au9r8J8nKaqmiL76RfD6JaF8k0sY0b8zxrRBD83nak6Jfg31fs0A==', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'      
        },
        body: JSON.stringify(convertToPostRequestBody()),
      });
      
    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {            
        console.log(`error on redirect to checkout ${result.error}`);
        navigate('/storeDown');
    }

    } catch (error) {
        console.log(`error creating session ${error}`);
        navigate('/storeDown');
    }

    setProcessing(false);
  };

  return  (
          <Container>
            Order Summary
            <Line />
            <ContainerRow><Title>Shipping</Title><Title>FREE</Title></ContainerRow>
            <ContainerRow><Title>Total</Title><Title>£{cartTotal}</Title></ContainerRow>            
            {processing ? <ProcessingButton>Processing...</ProcessingButton> : <PayButton disabled={processing} role="link" onClick={handleClick}><FontAwesomeIcon size="xs" icon={faLock} />&nbsp;&nbsp;Checkout</PayButton>}
          </Container>
          )
}

export default CartSummary;