import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Sizes } from './sizes';

export const Heading = styled.h1`
  font-size: 24px;    
  font-weight: 400;
  margin-top: 20px;
  margin-right: 5px;  
`;

export const HeadingSlim = styled(Heading)`
  margin-top:0;
  margin-bottom:0;
`;

export const Title = styled.h2`
  font-size: 16px;    
  font-weight: 400;  
  margin-bottom: 10px;
`;

export const Text = styled.span`
    font-size: 16px;   
    font-weight: 300;
`;

export const TextSlim = styled(Text)`
  margin-top:0;
`;

export const ButtonBase = styled.button`
    background: transparent;
    cursor: pointer;
    padding: 0.5em 0.75rem;
    border: none;
    border-radius: 0;
    visibility: ${props => props.hide ? "hidden" : "visible"};    
    display: flex;
    transition: all 0.5s ease;    

  :focus {    
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }   

`;

export const TwoColContainer = styled.div`
    display: flex;    
    flex-direction: column;
    justify-content: space-between;          

    @media screen and ${Sizes.mobileS} {
      flex-direction: row;            
    }
`;

export const Col1Image = styled.div`
  flex: 1;
`;

export const Col2Info = styled.div`
  margin-left: 30px;
  flex: 2;
`;

export const NavLink = styled(props => <Link {...props} />)`     
    font-weight: 400;
    text-align: center;  
    background:transparent;
    color: #515151;
    transition: color .2s ease-in;         
`;

export const StandardLink = styled.a`
    font-weight: 400;
    text-align: center;  
    background:transparent;
    color: #515151;
    transition: color .2s ease-in;       
`;